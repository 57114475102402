/* Header
================================================== */
header {
  
  .row {
    align-items: center;
    text-align: center;
    padding: 2rem;
  }
  .header {
  &__left {
    display: none;
    @include bp(smedium) {
      display: block;
      text-align: left;
    }
  }
  
  .social {
    margin: 0 2rem 0 0;
  }
  
  &__center {
    @include bp(large) {
      text-align: left;
    }
    img {
      height: 120px;
      margin-bottom: 1rem;
      @include bp(large) {
        margin: 0;
      }
    }
  }
  
  &__right {
    @include bp(large) {
      text-align: right;
    }
    p, a {
      margin: 0;
      text-wrap:nowrap;
    }
  }
  
}
}