/* Call To Actions
================================================== */
#cta-section {
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.cta-article {
  background-color: $off-white !important;
}
.cta {
  flex-flow: row wrap;
  width: 100%;
  gap: 0 !important;
  margin: 2rem 0;
  &__buttons {
    .button {
      width: 100%;
    }
  }
  & > div {
    position: relative;
    @extend .small-12;
    @extend .large-6
  }
  &__content {
    display: flex;
    flex-flow: row wrap;
    padding: 4rem 2.5rem;
    align-content: center;
    @include bp(large) {
      padding: 6rem;
    }
    * {
      width: 100%;
    }
    h2 {
      margin-top: 0;
      @include bp(large) {
      	font-size: 3.2rem;
      }
      /*@include bp_max(large) {
        max-width: 65%;
      }*/
    }
  }
  &__image {
    position: relative;
    border: 4px solid $white;
    @include bp(large) {
      min-height: 40rem;
    }
    @include bp_max(large) {
      height: 20rem;
    }
    @include bp_max(small) {
      height: 12rem;
    }
    p {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0;
    }
    img {
      object-fit: cover;
      object-position: 50% 50%;
      max-width: none;
      width: 100%;
      height: 100%;
    }
  }
  
  .button {
    position: absolute;
    bottom: 2rem;
    left: -6rem;
    z-index: 10;
    padding-left: 12rem;
    @include bp_max(smedium) {
      left: 0;
      padding-left: 4rem;
    }
  }
  
  &--1 {
    .cta__content {
      background-color: $primary-colour;
      * {
        color: $white;
      }
    }
    .cta__image {
      border-color: $primary-colour;
    }
    .button {
      &:hover {
        border-color: $primary-colour !important;
        color: $primary-colour !important;
      }
    }
  }
  &--2 {
    @include bp_max(large) {
      flex-direction: column-reverse;
    }
    .cta__content {
      background-color: $white;
    }
    .button {
      &:hover {
        border-color: $primary-colour !important;
        color: $primary-colour !important;
      }
    }
  }
  &--3 {
    .cta__content {
      background-color: $secondary-colour;
      * {
        color: $white;
      }
    }
    .cta__image {
      border-color: $secondary-colour;
    }
    .button {
      &:hover {
        border-color: $secondary-colour !important;
        color: $secondary-colour !important;
      }
    }
  }
}


/* Video Section
================================================== */
#video-section {
  padding: 2rem 0;
  background: $tertiary-colour;
  /*.n2_ss_video_player {
    @extend .border-style;
  }*/
}

/* Testimonials
================================================== */
#testimonials-section {
	margin-top: 4rem;
	@include bp(smedium) {
		margin-bottom: -4rem;
	}
}
.testimonials {
	&__banner {
      position: relative;
      z-index: 10;
      img {
        @extend .border-style;
      }
	}
	&Wrap {
		display: flex;
		flex-wrap: wrap;
	}
	&__articles {
		position: relative;
		bottom: 2rem;
		z-index: 20;
		overflow: hidden;
		padding: 4rem;
		background: $white;
		text-align: center;
		@include bp(smedium) {
			bottom: 8rem;
		}
		@include bp(large) {
			padding: 6rem 6rem 4rem;
		}
		@include bp(xlarge) {
			padding: 6rem 8rem 4rem;
		}
	}
	/*article {
		p {
			font-family: $secondary-font;
		}
		.entry-title {
			margin-bottom: 2.6rem;
    		font-size: 0.9rem;
		}
		.entry-content p {
			font-size: 1.6rem;
			font-style: italic;
			line-height: 150%;
		}
	}*/
	&__image {
        div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 3rem;
            text-align: center;
        }
		img {		
		  max-width: 200px;
		  width: 100%;
          margin: auto;
          @extend .border-style;
          @include bp(smedium) {
            max-width: 340px;
          }
          @include bp_max(large) {
             margin-left: 2rem;
          }
		}
	}
}