/* Banner
================================================== */
#banner {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 4rem;
  @include bp_max(large) {
    display: flex;
    flex-direction: column-reverse;
  }
  
  #home-2023 & {
   margin-top: 6rem; 
  }
  
  .n2-ss-slider .n2-ss-slide, .n2-ss-slider-3, .n2-ss-slider-2 {
    overflow: visible !important;
  }
  
  video {
    height: 320px;
    width: 100%;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    @extend .border-style;
    @include bp(smedium) {
      height: 460px;
    }
  }
}

.banner {
  &__overlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: calc(100% - 4rem);
    @include bp_max(large) {
      position: relative;
      top: -8rem;
      margin-bottom: -8rem;	
    }
    .row {
      align-items: center;
      height: 100%;
    }
    .moduletable {
      position: relative;
      top: -9rem;
      left: 0;
      @include bp(smedium) {
        top: -20rem;
        left: -9rem;
      }
      @include bp(large) {
        top: -7rem;
      }
      #home-2023 & {
        top: 4rem;
        @include bp(smedium) {
          top: 2rem;
        }
        @include bp(large) {
          top: -9.5rem;
        }
      }
    }
    h1 {
      padding: 1.5rem 4rem 1.5rem 12rem;
      color: $white;
      background: $tertiary-colour;
      @include bp(mlarge) {
        font-size: 3rem;
      }
      @include bp_max(smedium) {
        padding-left: 4rem;
        font-size: 1.5rem;
      }
      /*#home-2022 & {
        @include bp(mlarge) {
          font-size: 3.5rem;
        }
      }*/
    }
    .button {
      padding-left: 12rem;
      @include bp_max(smedium) {
        padding-left: 4rem;
      }
    }
  }
}