// 1. File Paths
// Usage - e.g. url('#{$image-directory}...')
// --------------
$public-path: "../";
$image-directory: "../images/";
$font-directory: "../fonts/";

// 2. Width
// --------------
$global-width: 1440px;

// 3. Fonts
// --------------

@import url("https://use.typekit.net/hvj5vrz.css");

@font-face {
	font-family: "geoslab703_md_btbold";
	src: url("#{$font-directory}geometric_slabserif_703_bold_bt-webfont.woff2")
			format("woff2"),
		url("#{$font-directory}geometric_slabserif_703_bold_bt-webfont.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

$primary-font: Georgia, serif;
$secondary-font: ivymode, sans-serif;
$tertiary-font: p22-underground, sans-serif;
$quaternary-font: "geoslab703_md_btbold";

// 4. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-colour: #d67540; // Peach Caramel
$secondary-colour: #e0b025; // Ceylon Yellow
$tertiary-colour: #007789; // Fanfare (turquoise)
$quaternary-colour: #d57595; // Chateau Rose

/// @type Map
$foundation-palette: (
	primary: $primary-colour,
	secondary: $secondary-colour,
	success: #3adb76,
	warning: #ffae00,
	alert: #cc4b37,
) !default;
$light-grey: #e6e6e6 !default;
$medium-grey: #cacaca !default;
$dark-grey: #8a8a8a !default;
$black: #0a0a0a !default;
$white: #ffffff !default;
$off-white: #f8f7f4 !default;
$dark-green: #2f3c3f; // Green Gables
$dark-blue: #2b3d50; // Majolica Blue
$brown: #704e48; // Cinnamon
$purple: #523744; // Prune
$dark-purple: #2c2c41; // Eclipse

// 5. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 100% !default;
$base-line-height: 1.5 !default;
$base-font-color: $primary-colour !default;
$base-font-weight: 400 !default;
$base-background-color: $white !default;

// 6. Headings
// --------------
$base-heading-font-family: $secondary-font !default;
$base-heading-line-height: 1.2 !default;
$base-heading-font-weight: 600 !default;
$base-heading-color: $primary-colour;
$base-h1-font-size: 2.6rem !default;
$heading-1-small: 2.4em;
$base-h2-font-size: 2.2rem !default;
$base-h3-font-size: 1.8rem !default;
$base-h4-font-size: 1.6rem !default;
$base-h5-font-size: 1.4rem !default;
$base-h6-font-size: 1.4rem !default;

// 7. Breakpoints
// --------------
//  Mixin scss classes e.g. @include bp(mlarge)
//  & Foundation breakpoint html class names e.g. class="small-6 xlarge-12"
$breakpoints: (
	small: 0,
	smedium: 480px,
	medium: 540px,
	mlarge: 768px,
	large: 960px,
	xlarge: 1024px,
	xxlarge: $global-width,
	xxxlarge: 1600px,
);
$breakpoint-classes: (small smedium medium mlarge large xlarge xxlarge xxlarge);

// 8. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
	small: 20px,
	medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: "columns";
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;
$global-lineheight: 1.5 !default;

// 9. Global
// --------------
$global-lineheight: 1.5;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 10. Transition
// --------------
$global-transition: all 0.6s ease-in-out;
