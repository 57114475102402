.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"], .com-baforms-wrapper .ba-form-submit-btn {
	display: inline-block;
	position: relative;
  	margin-right: 1rem;
  	margin-bottom: 1rem;
    padding: 0.8rem 3.5rem;
    background-color: transparent;
    border-top: 4px solid $primary-colour;
    border-bottom: 4px solid $primary-colour;
    border-left: 4px solid $primary-colour;
    border-right: none;
    color: $primary-colour !important;
    text-decoration: none;
  	text-align: center;
    font-size: 1.1rem;
  	font-family: $quaternary-font;
    transition: $global-transition;

    &:before, &:after {
      content: "";
      right: 0;
      width: 4px;
      background-color: $primary-colour;
      height: 12px;
      position: absolute;
      transition: $global-transition;
    }

    &:before {
      bottom: 0;
    }

    .arrowIcon {
      @extend .arrowIcon-style;
      transition: $global-transition;
    }

    &:after {
      top: 0;
    }

	&:hover {
		background: $primary-colour;
		color: $white !important;
		.arrowIcon {
			right: -20px !important;
			opacity: 0;
		}
	}

	&:active,
	&:focus {
		background: $primary-colour;
    	color: $white !important;
	}
}


.button {
  &--solid {
    background: $white;
    border: 4px solid $white;
    &:hover {
      color: $tertiary-colour !important;
      background: $white !important;
      border-color: $tertiary-colour !important;
    }
    .arrowIcon {
      right: 1rem !important;
    }
    &:before, &:after {
      display: none;
    }
  }
  
  &--1 {
    border-color: $primary-colour;
    &:hover, &:active, &:focus {
      background: $primary-colour;
    }
    &:before, &:after {
      background-color: $primary-colour;
    }
  }
  
  &--2 {
    border-color: $secondary-colour;
    &:hover, &:active, &:focus {
      background: $secondary-colour;
    }
    &:before, &:after {
      background-color: $secondary-colour;
    }
  }
  
  &--3 {
    border-color: $tertiary-colour;
    &:hover, &:active, &:focus {
      background: $tertiary-colour;
    }
    &:before, &:after {
      background-color: $tertiary-colour;
    }
  }
  
}

.button-wrap-center {
  text-align: center;
}

.arrowIcon-style {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    margin-top: 2px;
    pointer-events: none;
    opacity: 1;
}



/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
