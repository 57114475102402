body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-font-color;
  font-weight: $base-font-weight;
  background: $base-background-color;
}

main,
details {
  display: block;
}

p,
li {
  margin-bottom: 1rem;
  color: $primary-colour;
  font-size: 1rem;
}

p img {
  margin: 0;
}
p.lead {
  font-size: 1.4em;
}

a {
  color: $secondary-colour;
  background-color: transparent;
  text-decoration: none;
  @include transition($global-transition);
  &:hover {
    color: $base-font-color;
  }
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.875rem;
}

.large {
  font-size: 2rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

ul,
ol {
  margin: 0 0 1rem 1rem;
  list-style-position: inside;
}
ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}
%no-bullet-style {
  margin: 0;
  list-style: none;
}


/*	Blockquotes  */
blockquote, blockquote p {
  font-size: 17px;
  line-height: 24px;
  color: #777;
  font-style: italic;
}
blockquote {
  margin: 0 0 20px;
  padding: 9px 20px 0 19px;
  border-left: 1px solid #ddd;
}
blockquote cite {
  display: block;
  font-size: 12px;
  color: #555;
}
blockquote cite:before {
  content: "\2014 \0020";
}
blockquote cite a, blockquote cite a:visited, blockquote cite a:visited {
  color: #555;
}

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 10px 0px;
  height: 0;
  min-width:300px;
}
