/* Footer
================================================== */
footer {
  padding: 2rem 0;
  h5 {
    font-family: $quaternary-font;
  }
  a {
    color: $primary-colour;
    &:hover {
      color: $tertiary-colour;
    }

    
  }

  .social {
    @include bp_max(medium) {
      margin: 2rem auto 0;
    }
  }

  .footer { 
  &Top {
    margin: 4rem 0 6rem;
    text-align: center;
    p {
      max-width: 640px;
      margin: 0 auto 2rem;
    }
    img {
      width: 560px;
    }
  }
  &Middle {
    @extend .row;
    max-width: 1920px;
    margin-bottom: 4rem;
    & > div {
      @extend .columns;
      @extend .small-12;
      @extend .medium-6;
      @extend .large-3;
      text-align: center;
      @include bp(medium) {
        //@extend .small-6;;
        text-align: left;
      }
    }
  }
  &OpeningTimes {
    span {
      display: block;
      @include bp(smedium) {
        display: inline-block;
      	width: 180px;
        text-align: left;
      }
      @include bp(large) {
        display: block;
      }
      @include bp(xlarge) {
        display: inline-block;
      	width: 180px;
      }
    }
  }
 
  &Bottom {
    @extend .row;
    max-width: 1920px;
    * {
    	font-size: 0.8rem;
    }
    & > div {
      @extend .columns;
      @extend .small-12;
      @extend .large-6;
      text-align: center;
      @include bp(large) {
        //@extend .small-6;;
      }
    }
    &__left {
      p {
        margin-bottom: 0.5rem;
      }
      @include bp(large) {
      	text-align: left !important;
      }
    }
    &__right {
      @include bp(large) {
      	text-align: right !important;
      }
    }
    img {
      width: 200px;
    }
  }
}
}