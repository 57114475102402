button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  -webkit-appearance: none;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */

.com-baforms-wrapper {
  .ba-form-field-item .ba-field-label-wrapper * {
    color: $primary-colour;
    font-size: $global-font-size;
    font-family: $secondary-font;
    font-weight: 600;
  }
  .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input, .ba-form-page-navigation-title, .upload-file-btn, .upload-file-input, .ba-field-container select, .ba-field-container textarea[readonly], .ba-field-container textarea, .ba-field-container input[type="text"], .ba-field-container input[type="number"], .ba-field-container input[type="password"], .ba-field-container input[type="email"], 
  .ba-form-acceptance-field .ba-form-acceptance-html p, .ba-form-acceptance-field .ba-form-acceptance-html, .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
      color: $primary-colour;
      font-size: $global-font-size;
      font-family: $primary-font;
      font-weight: normal;
  }
  .ba-form-submit-btn {
      display: inline-block;
      position: relative !important;
      height: auto !important;
      margin-top: 1rem !important;
      margin-right: 1rem !important;
      margin-bottom: 1rem !important;
      padding: 0.8rem 3.5rem !important;
      background-color: transparent !important;
      border-top: 4px solid $primary-colour !important;
      border-bottom: 4px solid $primary-colour !important;
      border-left: 4px solid $primary-colour !important;
      border-right: none !important;
      border-radius: 0 !important;
      color: $primary-colour !important;
      text-decoration: none !important;
      font-size: 1.1rem !important;
      font-family: $quaternary-font !important;
      line-height: $base-line-height !important;
      transition: $global-transition !important;

      &:before, &:after {
        content: "" !important;
        right: 0 !important;
        width: 4px !important;
        background-color: $primary-colour !important;
        height: 12px !important;
        position: absolute !important;
        transition: $global-transition !important;
      }

      &:before {
        bottom: 0 !important;
        animation: none !important;
        border-radius: 0 !important;
        border: none !important;
        left: auto !important;
        opacity: 1 !important;
        top: auto !important;
        z-index: 100 !important;
      }

      .arrowIcon {
        @extend .arrowIcon-style;
        transition: $global-transition !important;
      }

      &:after {
        top: 0 !important;
      }

      &:hover {
          background: $primary-colour !important;
          color: $white !important;
          .arrowIcon {
              right: -20px !important;
              opacity: 0 !important;
          }
      }
    }
  .ba-forms-authorize-pay-btn *, .ba-form-submit-btn * {
    transition: all 0s !important;
  }
}
