/* Content Box
================================================== */
.contentBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin: 3em auto;
	&__content,
	&__image {
		width: 48%;
		@include bp_max(mlarge) {
			width: 100%;
		}
	}

	&--wrap {
		margin: 0em auto;
		padding: 4em 0 0 0;

		@include bp_max(smedium) {
			padding: 2em 0 0 0;
		}
	}

	&__image {
		img {
			width: 100%;
		}
	}
}

/* Visiting Component
================================================== */
.visitRow {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin: 2em 0;

	&__image {
		width: 15em;
		height: 15em;
		display: flex;
		justify-content: center;
		align-items: center;
		background: $primary-colour;

		@include bp_max(smedium) {
			width: 100%;
		}

		svg {
			width: 7em;
			height: 7em;

			path {
				fill: $white;
			}
		}

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	&__content {
		width: calc(100% - 17em);
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include bp_max(smedium) {
			width: calc(100% - 2px);
			padding: 1em;
		}
	}
}

/* Testimonials
================================================== */
.testimonials {
	&--full {
		.latestnews-items {
			-webkit-flex-direction: row !important;
			-ms-flex-direction: row !important;
			flex-direction: row !important;
			-webkit-justify-content: normal !important;
			-ms-flex-pack: normal !important;
			justify-content: normal !important;
		}
		.latestnews-item {
			@extend .columns;
			@extend .small-12;
			align-items: center !important;
			flex: 0 0 100% !important;
			width: 100%;
			max-width: 100%;

			margin-bottom: 4rem !important;

			@include bp(smedium) {
				align-items: normal !important;
				flex: 0 0 50% !important;
			}

			@include bp(large) {
				max-width: 50% !important;
			}

			.newsintro,
			.newsextra {
				text-align: center !important;
			}
			.item_details {
				margin-bottom: 2rem;
			}
			.detail_data {
				display: block;
				font-family: $secondary-font;
				font-size: 1rem;
				font-weight: 300;
			}
			.detail_jfield_1 {
				.detail_data {
					font-weight: bold;
				}
			}
		}
	}
}
