/* Navigation
================================================== */
.nav--main {
  position: relative;
  z-index: 90;
  background-color: $white;
  &.shrink {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
}

.nav {
  	display: none;
    width: auto;
    margin: 0;

    @include bp(xlarge) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
    
    @include bp_max(xlarge) {
      li {
        width: 100%;
        text-align: center;
      }
    }
    
    li {
      position: relative;
      text-align: center;
      list-style: none;
      left: 0;
      margin-bottom: 0;
    }
    
    a {
      display: block;
      padding: 1rem 1.9rem;
      color: $secondary-colour;
      font-size: 0.9em ;
      text-transform: uppercase;
      font-family: $tertiary-font;

      @include bp(xlarge) {
        color: $primary-colour;
      }
      @include bp(xxlarge) {
        font-size: 1em ;
      }
    }
    
    a:hover, .current > a, .active > a {
      color: $secondary-colour;
    }
    
    ul {
      display: none;
      &.active {
        display: block;
      }
    }
    
    .parent {
      > a {
        position: relative;
        
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 45%;
          right: 1rem;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          background: $secondary-colour;
          @include border-radius(50%);
        }
      }
      
      &:hover {
        ul.mod-menu__sub {
          @include bp(large) {
          	display: flex;
          }
        }
        
        > a {
          &:after {
            width: 0;
            height: 0;
            @include border-radius(0);
            border-style: solid;
            border-width: 7px 5px 0 5px;
          	border-color: $secondary-colour transparent transparent transparent !important;
            background: none;
          }
        }
      }
    }
    
    .mod-menu__sub {
      display: none;
      flex-flow: column nowrap;
      list-style: none;
      margin: 0;
      background: $secondary-colour;

      a {
        color: white;
      }
      
      @include bp(xlarge) {
        position: absolute;
        top: 100%;
        min-width: 100%;
        width: 240px;
        align-items: center;
        background: $white;
        border-bottom: $secondary-colour 5px solid;

        a {
          color: $primary-colour;

          &:hover {
            color: $secondary-colour;
          }
        }
      }
    }
  }
  
  .menuActive {
    .nav {
        display: flex;
        position: absolute;
        left: 0;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-left: 0;
        padding-top: 8em;
        background: $white;
        border-bottom: $secondary-colour 5px solid;

        &:after {
          content: ""; 
          display: block;
          border-bottom: $secondary-colour 1px solid;

        }
       
        @include bp_max(smedium) {
          padding-top: 5em;
        }
    }
}


/* Hamburger
================================================== */
.menuToggle {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    min-width: 110px;
    margin: 0 0 0 1rem;
    padding: 20px 16px;
    outline: none;
    text-align: left;
    background-color: transparent!important;
  
    @include bp(xlarge) {
      display: none;
    }
  
    &:hover {
      p {
        color: $tertiary-colour !important;
      }
      span:before, span:after {
        background-color: $tertiary-colour !important; 
      }
    }
  
    p {
      margin: 0 32px 0 0;
      font-size: 1.1rem;
      font-family: $quaternary-font;
      transition: $global-transition;
    }
  
    span:before, span:after {
      content: '';
      position: absolute;
      top: 45%;
      right: 0;
      display: inline-block;
      width: 24px;
      height: 3px;
      background-color: $primary-colour;
      margin: 0 auto;
      transition: $global-transition;
    }

    span {
      position: relative;
      display: block;
      &:before {
        top: -6px;
      }
      &:after {
        top: auto;
        bottom: -6px;
      }
    }

    .menuActive & {
      p {
        color: $secondary-colour;
      }
      span {
        &:before, &:after {
          top: 0;
          background: $secondary-colour;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
}