/* News
================================================== */
.newslist {
	.latestnews-items {
		flex-flow: column !important;
		//align-items: normal !important;
		align-items: center !important;
		-webkit-justify-content: normal !important;
		-ms-flex-pack: normal !important;
		justify-content: normal !important;
		overflow: visible !important;
		@include bp(large) {
			align-items: normal !important;
		}
		@include bp(large) {
			flex-flow: row wrap !important;
		}
	}
	.latestnews-item {
		flex: 1;
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 0px;
		-ms-flex: 1 1 0px;
		flex: 1 1 0px;
		padding-right: 0.9375rem;
		padding-left: 0.9375rem;
		min-width: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 1rem 3rem !important;
		@include bp(large) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		@include bp(xlarge) {
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
		}
		& > a {
			display: block;
			height: 100%;
			&:hover {
				.detail_category,
				.detail_tags {
					opacity: 1;
				}
				.link {
					a {
						background-color: $primary-colour;
						span {
							color: $white;
						}
					}
				}
			}
		}
		.newsSection & {
			border: 0;
		}
		&.active {
			opacity: 1 !important;
		}
	}
	.newshead {
		float: none !important;
		margin: 0 !important;
	}
	.picture {
		max-width: none !important;
		max-height: none !important;
	}
	.innerpicture {
		width: 100%;
		&:after {
			content: "";
			display: block;
			padding-bottom: 60%;
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.news {
		position: relative !important;
		height: 100% !important;
		margin: 0 !important;
		padding: 0rem !important;
		background-color: $off-white !important;
		border: none !important;
	}
	.newstitle {
		font-size: 1.6rem;
		font-family: $secondary-font !important;
		font-weight: normal !important;
		line-height: $base-line-height !important;
		margin: 0 0 0.5em 0 !important;
		a {
			color: $primary-colour;
			&:hover {
				color: $secondary-colour;
			}
		}
	}
	.newsinfo {
		padding: 1.5rem 1rem 4rem !important;
		* {
			color: $primary-colour;
		}
	}
	.newsintro {
		line-height: $base-line-height !important;
	}
	.detail_category,
	.detail_tags {
		opacity: 1;
		display: inline-flex;
		padding: 0.4rem 0.8rem;
		border-radius: 50px;
		background: $off-white;
		font-family: $primary-font;
		font-weight: 700;
		font-size: 0.8rem;
		color: $primary-colour;
		transition: $global-transition;
		@include bp(large) {
			opacity: 0.25;
		}
	}
	.detail_date {
		display: flex;
		align-items: center;
		margin: 0 0 0.25rem 0;
		text-transform: uppercase;
		font-family: $quaternary-font;
		font-size: 0.6rem;
		font-weight: 400;
	}
	.delimiter {
		display: none;
	}
	.link {
		width: 100% !important;
		margin-top: 1.5em !important;
		a {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0.5rem 0;
			background-color: $white;
			border: 4px solid $off-white;
			color: $primary-colour;
			text-align: center;
			font-family: $tertiary-font;
			text-transform: uppercase;
		}
		span {
			transition: $global-transition;
		}
	}
	&--full-width {
		.latestnews-item {
			@include bp(large) {
				flex: 0 0 100% !important;
				max-width: 100% !important;
			}
		}
	}
	&--full-details {
		.latestnews-item {
			.newsinfo {
				padding: 1.5rem 1rem !important;
			}
			ul,
			p {
				margin-bottom: 1rem !important;
			}
		}
	}
}

#news {
	main {
		max-width: 1200px;
		margin: 3rem auto 0;
	}
	.sharethis-inline-share-buttons {
		margin: 4rem 0 2rem;
	}
}

// News Section HP
.news-module {
	background: $white;
	padding: 3rem;
	#lnee_235 {
		margin-top: 3rem;
		/* .latestnews-item {
			.newshead {
				.picture {
					text-align: initial;
				}
			}
		}
        .newsinfo {
            background: $white;
        }

        .newslist {  .link  { 
            

	}

	
} */
	}
}

/* News Page
  ================================================== */
.news-item-article {
	.main-content {
		flex-direction: column;
		padding: 0;
	}
	.published {
		margin: 0;
		font-family: $primary-font;
		text-transform: uppercase;
		font-weight: 400;
	}
	.com-content-image,
	.item-page {
		max-width: 960px;
		width: 100%;
		margin: auto;
	}
	.com-content-image {
		margin-bottom: 0;
		figure {
			position: relative;
			margin: 0;
			background-color: $white;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			max-height: 600px;
			object-fit: cover;
		}
	}
	.item-page {
		padding: 3em;
		background-color: $white;
		* {
			color: $base-font-color;
		}
	}
	.article-info.muted {
		display: block;
		color: $primary-colour;
		margin-bottom: 2em;
	}
}

.share-buttons {
	.button-wrap--center {
		display: flex;
		justify-content: center;
	}
}

.st-btn {
	background-color: $secondary-colour !important;
}

.sharethis-inline-share-buttons {
	margin-top: 4rem;
	margin-bottom: 2rem;
}

.article-info.muted {
	display: none;
}
