/* Main
================================================== */

body {
  position:relative;
  background: $off-white;
}

.half-left, .half-right {
  width:49%;
  float:left;
  overflow:auto;
}

.half-right {
  margin-left:1%;
}


main {
	display: flex;
	flex-wrap: wrap;
  margin-top: 3rem;

    @include bp(large) {
      flex-wrap: nowrap;
    }
  
    #about-us &,
  	#show-essentials &,
  	#admissions-policy & {
      display: none;
    }


  
	&.has-sidebar {
		article {
			@extend .small-12;
      //@extend .large-8;
          	@include bp(large) {
              -webkit-flex: 0 0 66.66667%;
              max-width: 100% !important;
              padding-right: 6rem;
          	}
          	@include bp(xxlarge) {
        
              padding: 5rem 8rem 5rem 5rem
          	}
		}
	}
	article {
		width: 100%;
		padding: 3rem 0;
		//background: $white;
    @include bp(smedium) {
      padding: 3rem 2.5rem;
    }

		/*@include bp(medium) {
		//padding: 6rem 4rem;
		}
		/*@include bp(xlarge) {
			padding: 10rem;
		}*/
      h1 {
        @include bp_max(medium) {
          font-size: $heading-1-small;
        }
      }
      #home-2022 & {
        background: $secondary-colour;
        * {
          color: $white;
        }
      }
	}
	.entry-content {
    @extend .large-8;
	
		// Home
		#home & {
      @extend .medium-12;
			
		}
	}
}


/* Sidebar
================================================== */
aside {
	position: relative;
	@extend .small-12;
  @extend .large-4;
	@include bp_max(large) {
	    //@extend .small-4;
	}
}

.sidebar {
	&--image {
      p {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 3rem 3rem 4rem 3rem;
      }
	}
	&Image {
        position: static;
        align-self: center;
        width: 45%;
        max-width: 340px;
      	@extend .border-style;
        #home-2022 & {
          border-color: $secondary-colour;
        }
        @include bp(large) {
          position: absolute;
          width: 55%;
        }
        &--1 {
            top: 6rem;
            left: -8rem;
        }

        &--2 {
            position: relative;
            top: 2rem;
            @include bp(large) {
                position: absolute;
                top: 9rem;
                right: 2rem;
            }
        }
  	}
}

/* Social
================================================== */
.social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    width: auto;
    margin-bottom: 0;

    svg {
      width: 1.4em;
      height: 1.4em;

      * {
        transition: $global-transition;
      }

      &:hover {
        path {
          fill: $tertiary-colour;
        }
      }
    }
  }
  
  .social-fixed & {
    position: fixed;
    top: 40%;
    right: 0;
    z-index: 100;
    display: none;
    margin: 0;
    background: $white;
    @include bp(large) {
      display: block;
    }
    h3 {
      display: none;
    }
    li {
      width: 50px;
      height: 56px;
      float: right;
      clear: right;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    span {
        display: none;
      }
    a {
      display: block;
      margin: 0;
      padding: 14px;
      color: $white;
      line-height: 100%;
      transition: $global-transition;
      &:hover {
        svg {
          * {
            fill: $tertiary-colour;
          }
        }
      }
    }
  }
}